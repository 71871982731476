.notification-button {
    cursor: pointer;
    transition: 0.2s;
}

.notification-button:hover {
    opacity: 0.85;
}

.notification-item-container {
    margin: 2px 0;
    border-radius: 5px;
    cursor: default;
    transition: 0.3s;
}

.notification-item-container:hover {
    background-color: #ededed;
    cursor: pointer;
}

.notification-links-container {
    display: none;
    margin-top: 10px;
}

.notification-item-container:hover .notification-links-container {
    /* display: block; */
}

.notification-item-read-button {
    float: right;
    cursor: pointer;
    font-size:1.2em;
    z-index: 3;
    /* transition: 0.2s; */
}

.notification-item-read-button:hover {
    /* opacity: 0.7; */
}

.notification-link {
    margin-right: 10px;
    transition: 0.2s;
    cursor: pointer;
}

.notification-link:hover {
    opacity: 0.8;
}