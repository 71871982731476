#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout .site-layout-background {
  margin: 10px 0px !important;
  background: #fff;
}
.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  margin: 16px;
}

.ant-menu-item {
  font-size: 1.2em;
}

/* .ant-menu-sub span {
    font-size: 1.0em;
  } */

.ant-menu-submenu-title span {
  font-size: 1.2em;
}

.header-icons-container {
  float: right;
  margin-right: 100px;
  font-size: 1.2em;
}

.header-icon {
  padding: 5px;
}

.sidemenu-link {
  color: #fff;
}

/* .site-page-header-container > .ant-page-header-heading > .ant-page-header-heading-extra {
  width: 60%;
} */

.site-page-header-container > .ant-page-header-heading {
  flex-wrap: nowrap !important;
}