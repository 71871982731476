/* .login-form-container {
    left: 50%;
    top: 50%;
    position: absolute;
    -webkit-transform: translate3d(-50%, -50%, 0);
    -moz-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
} */

/* 
.new-login-form {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    background-color: #fff; 
    padding:32px;
     border-radius: 10px;
} */

/* Create two equal columns that floats next to each other */
.login-image-container {
    border-right: 1px solid #f0f0f0;
    background-size: cover;
    float: left;
    width: 65%;
    height: 100%;
    
}



.login-form-container {
    float: left;
    width: 35%;
    position: relative;
    height: 100%;
 
    vertical-align: middle;
}
.login-form{
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.form-title {
    text-align: center;
}

/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}

.row{
    width: 100%;
    height: 100%;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .column {
        width: 100%;
    }
}

@media only screen and(max-width: 768px) {
    .mobileHide {
        display: none;
    }
    .login-form-container {
        width: 100% !important;
        padding: 10px;
       
    }
}


.block {
	width: 100%;
	height: 100%;
	/* background: linear-gradient(150deg, #53f 15%, #05d5ff 70%, #a6ffcb 94%); */
	transform: skewY(-12deg);
	transform-origin: 0;
	position: absolute;
	top: 0;
}
.block span {
	height: 50px;
	position: absolute;
}
.block span:nth-child(1) {
    height: 50px;
	width: 20%;

    top:220px;
	/* left: -16.66666%;
	left: calc(calc(calc(100% / 3) / 2) * -1); */
	background: #80E9FF;
}
.block span:nth-child(2) {
    height: 50px;
	width: 40%;
	top: 220px;
	right: 0;
	/* left: calc(calc(100% / 3) / 2);
	right: auto; */
	background: #fa541c;
}
.block span:nth-child(3) {
    height: 50px;
	/* width: 33.33333%; */
	width: calc(100% / 5);
	left: 60%;
	/* left: calc(calc(calc(100% / 3) / 2) + calc(100% / 3)); */
	bottom: 10px;
	background: #4f40ff;
}
.block span:nth-child(4) {
	width: 25%;
	width: calc(100% / 3);
	bottom: 0;
	background: #73d13d;
}

/* TEXT */
.text {
    
	position: relative;
	height: 760px;
	display: flex;
	margin: -60px 0 0 150px;
	flex-direction: column;
	justify-content: center;
	/* color: #fff; */
	max-width: 1024px;
}
.text h1 {
  font-size: 40px;
  opacity: 0;
  animation: fadein 1s;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
}
.text p {
	/* color: #d9fcff; */
	font-size: 20px;
	max-width: 50%;
	font-size: 17px;
  line-height: 28px;
  opacity: 0;
  animation: fadein 1s;
  animation-delay: 0.8s;
  animation-fill-mode: forwards;
}


@keyframes up {
	0% {
		transform: translateY(100px) rotate(-12deg);
		opacity: 0;
	}
	100% {
		transform: translateY(0) rotate(-12deg);
		opacity: 1;
	}
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 830px) {
  .img-wrapper {
    display: none;
  }
}

@media screen and (max-height: 600px) {
	.text {
		margin: -200px 0 0 150px;
	}
	.block {
		height: 500px;
	}
	.img-wrapper {
		top: 280px;
	}
}
.copy-right-footer {
    position: absolute;
    bottom: 0;
}