/* index.css */
/* @import '~antd/dist/antd.css'; */
body {
  /* position: fixed; */
  font-family: sans-serif !important;
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-stroke-width: 0.2px;
  margin: 0px;
  /* height: 100%; */
}

#root {
  height: 100%;

}

.heading {
  font-weight: 700;
}

.subheading {
  font-weight: 300;
}

.App {
  height: 100%
}

.ant-input-clear-icon {
  font-size: 14px !important;
}

.ant-input-textarea-clear-icon {
  font-size: 20px !important;
}

.btn-lg-main {
  border-radius: 5px 5px !important;
  font-size: larger !important;
  font-weight: 500 !important;
}

.btn-lg-main:hover {
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.hover-with-shadow:hover {
  box-shadow: 0 4px 6px 0 rgba(16, 25, 40, .1) !important;
}

.btn-primary {
  border-radius: 5px 5px !important;
  font-size: larger !important;
  font-weight: bolder !important;
}


.success-message>.ant-message-notice-content {
  background-color: #F6FFED;

  border-radius: 5px;
  border: 1px solid #b7eb8f;
  padding: 10px;
}

.error-message>.ant-message-notice-content {
  background-color: #FEF2F0;

  border-radius: 5px;
  border: 1px solid #ffccc7;
  padding: 10px;
}

.no-margin {
  margin: 0px
}


/* Notification count animation */
/* .ant-badge-count {
  animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    box-shadow: 0 0 0 18px rgba(255, 82, 82, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
} */



::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #8c8c8c;
}


.admin-pin-code {
  -webkit-text-security: square;
}


.ant-modal-content {
  border-radius: 8px !important;
}

.ant-modal-header {
  border-radius: 8px !important;
}